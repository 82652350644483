<template>
  <van-action-sheet
    class="choose-merchant-box"
    v-model="show"
    title="选择商户"
    :close-on-click-overlay="false"
    @cancel="handleCancel"
    close-icon="clear"
  >
    <div class="content">
      <div class="table-list">
        <div
          class="list-item"
          v-for="item in 10"
          :key="item"
          @click="clickItem(item)"
        >
          <span class="merchant-name">战三的大声道大大的</span>
          <span class="arrow-box"><van-icon class="arrow" name="arrow" /></span>
        </div>
      </div>
    </div>
  </van-action-sheet>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { ActionSheet, Icon } from "vant";
export default {
  name: "ChooseMerchant",
  components: {
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // ...mapState(["showMerchant"]),
    show: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("input", false);
      },
    },
  },
  methods: {
    // ...mapMutations(["setMerchantShow"]),

    // 取消
    handleCancel() {
      this.$emit("handleCancel");
    },

    // 选择商户
    clickItem(item) {
      this.handleCancel();
    },
  },
};
</script>

<style lang='less' scoped>
.choose-merchant-box {
  height: 80vh;
  .van-action-sheet__header {
    font-size: 0.36rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .van-action-sheet__content {
    overflow: hidden;
  }

  .content {
    height: 100%;
    overflow: hidden;

    .table-list {
      height: 100%;
      overflow-y: auto;
      padding: 0.8rem 0.48rem;
      box-sizing: border-box;

      .list-item {
        height: 1.2rem;
        background: #ffffff;
        box-shadow: 0rem 0.02rem 0.12rem 0rem #e8e8e8;
        border-radius: 0.08rem;
        margin-bottom: 0.32rem;
        padding: 0 0.48rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .merchant-name {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303133;
          line-height: 0.4rem;
        }
        .arrow-box {
          color: #e8e8e8;
          font-size: 0.26rem;
        }
      }
    }
  }
}
</style>