<template>
  <div class="setting-page">
    <div class="user-info">
      <div class="user-avatar">
        <img src="../assets/images/user_icon.png" alt="" />
      </div>
      <div class="user-name">
        <span class="name">用户名称</span>
        <span class="uid">UID:156123131</span>
      </div>
    </div>
    <div class="action-box">
      <van-cell-group inset>
        <van-cell title="切换商户" is-link @click="chengeMerchant" />
        <van-cell
          center
          title="更换手机号"
          value="13599996666"
          label="若手机更换请尽快修改"
          is-link
          @click="chengeMobile"
        />
      </van-cell-group>
    </div>
    <div class="exit-login">退出登录</div>
    <choose-merchant
      v-model="showMerchant"
      @handleCancel="handleCancelMerchant"
    />
    <change-mobile
      v-model="showMobile"
      @onCancel="showMobile = false"
      @onOk="chengMobileConfirm"
      :step="step"
    />
  </div>
</template>

<script>
import { Cell, CellGroup, Icon } from "vant";
import ChooseMerchant from "../components/ChooseMerchant.vue";
import ChangeMobile from "../components/ChangeMobile.vue";
export default {
  name: "Setting",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    ChooseMerchant,
    ChangeMobile,
  },
  data() {
    return {
      showMerchant: false,
      showMobile: false,
      step: 2,
    };
  },
  methods: {
    // 切换商户
    chengeMerchant() {
      this.showMerchant = true;
    },

    // 修改手机号
    chengeMobile() {
      this.showMobile = true;
    },

    chengMobileConfirm() {
      this.showMobile = false;
    },

    // 取消切换商户
    handleCancelMerchant() {
      this.showMerchant = false;
    },
  },
};
</script>

<style lang='less' scoped>
.setting-page {
  height: 100%;
  overflow-y: auto;

  .user-info {
    height: 2.16rem;
    background: #ffffff;
    display: flex;
    padding: 0 0.4rem;

    .user-avatar {
      width: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.32rem;

      img {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    .user-name {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        font-size: 0.36rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      .uid {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        margin-top: 0.08rem;
      }
    }
  }

  .action-box {
    background: #ffffff;
    margin-top: 0.2rem;
  }

  .exit-login {
    height: 0.92rem;
    background: #ffffff;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
  }
}
</style>